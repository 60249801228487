document.addEventListener("DOMContentLoaded", function () {
    let social_links = document.querySelectorAll('.wp-block-social-link a');
    if (social_links.length != 0) {
        const currentUrl = window.location.href;
        if (social_links.length > 1) {
            social_links.forEach((item) => {
                let currentHref = item.getAttribute('href');
                item.setAttribute('href', currentHref + currentUrl);
                item.setAttribute('target', '_blank');
            });
        } else {
            let currentHref = social_links[0].getAttribute('href');
            social_links[0].setAttribute('href', currentHref + currentUrl);
            social_links[0].setAttribute('target', '_blank');
        }
    }
});